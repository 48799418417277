import { sumBy, keyBy, values, maxBy, compact } from 'lodash';

import {
	radarHighToAverageBoundariesRatio,
	radarAverageToLowBoundariesRatio,
	radarLikelyToUndefinedBoundariesRatio
} from 'components/Radar/MainRadar';
import {
	getItemAlteredByScenario,
	isItemInactive,
	vulnerabilityConstraints,
	vulnerabilityIndicators
} from './rules';

export const getScoreColor = (value: number) => {
	let color2 = [231, 5, 73];
	let color1 = [0, 176, 169];

	var w1 = value / 100;
	var w2 = 1 - w1;
	var rgb = [
		Math.round(color1[0] * w1 + color2[0] * w2),
		Math.round(color1[1] * w1 + color2[1] * w2),
		Math.round(color1[2] * w1 + color2[2] * w2)
	];
	return 'rgb(' + rgb.join(',') + ')';
};

export const getItemScenarioScore = (item: any, scenario: any) => {
	return vulnerability(
		[item].map((s: any) => getItemAlteredByScenario(s, scenario))
	);
};

export const getItemScore = (item: any, evaluations: any) => {
	let indexedEvaluations = keyBy(evaluations, 'itemId') || {};

	if (indexedEvaluations[item.id]) return indexedEvaluations[item.id].value;

	return 50;
};

const red = [231, 5, 73];
const green = [0, 176, 169];
const white = [255, 255, 255];

export function colorToPercent(val) {
	if (val == 'orange') return 100 / 3;

	if (val == 'red') return 0;

	if (val == 'green') return 100;
	if (val == 'blue') return (100 * 2) / 3;

	if (val === null || val === 'undefined') {
		return 50;
	}

	return parseInt(val);
}

export function percentToHue(val: any, start, end) {
	return [
		((100 - val) * start[0] + val * end[0]) / 100,
		((100 - val) * start[1] + val * end[1]) / 100,
		((100 - val) * start[2] + val * end[2]) / 100
	];
}

export function percentToSingleHue(val: any, isPositive) {
	if (isPositive) {
		return percentToHue(val, white, green);
	}

	return percentToHue(val, red, white);
}

export function hue(val: any) {
	var h = percentToHue(val, red, green);

	//console.log('hue rgb(' + h[0] + ', ' + h[1] + ', ' + h[2] + ')');
	return 'rgb(' + h[0] + ', ' + h[1] + ', ' + h[2] + ')';
}

export function singleHue(val: any, isPositive) {
	var h = percentToSingleHue(val, isPositive);

	//console.log('singleHue rgb(' + h[0] + ', ' + h[1] + ', ' + h[2] + ')');
	return 'rgb(' + h[0] + ', ' + h[1] + ', ' + h[2] + ')';
}

export const getFishBoneBranchScore = (branch: any) => {
	const pointsWeight = sumBy(branch.items, (p) => p.weight || 1);

	//console.group('getFishBoneBranchScore');

	//console.log('branch', branch.name, 'pointsWeight', pointsWeight);
	let result =
		branch.items && branch.items.length > 0
			? sumBy(branch.items, (p) => {
					const completion = parseInt(p.completion) || 0;
					const score =
						p.trend !== null && p.trend !== undefined
							? parseInt(p.trend)
							: 50;
					const remaining = 100 - completion;
					const scoreOfRemaining = score * (remaining / 100);
					const finalScore = completion + scoreOfRemaining;

					return finalScore * (p.weight || 1);
				}) / pointsWeight
			: null;

	//console.groupEnd();
	return result;
};

export const getFishboneScore = (branchs: any) => {
	let weight = 0;

	//console.group('getFishboneScore');
	let branchScores = branchs.map((b) => {
		const score =
			b.items && b.items.length > 0 ? getFishBoneBranchScore(b) : null;
		weight += score !== null ? b.weight || 1 : 0;
		return score !== null ? score * (b.weight || 1) : null;
	});

	branchScores = compact(branchScores);

	//console.log('branchScores', branchScores);

	//console.groupEnd();

	return branchScores.length > 0
		? sumBy(branchScores, (v) => v) / weight
		: null;
};

export const getIndividualRiskScore = (element) => {
	let likelihood = parseFloat(element.likelihood || element.o?.likelihood);
	let impact = parseFloat(element.impact || element.o?.impact);

	//console.log("item ", element.name, likelihood, impact, "score", likelihood * impact * impact);

	return likelihood * impact * impact;
};

export const getRiskScore = (elements: any, maxLikelihood, maxImpact) => {
	if (!elements || elements.length == 0) {
		return null;
	}

	//console.group('getRiskScore');

	var total = 0;
	let weights = 0;
	let count = 0;

	//console.log('elements', elements.length);

	for (var i = 0; i < elements.length; i++) {
		let likelihood = parseFloat(
			elements[i].likelihood || elements[i].o?.likelihood || 1
		);
		let impact = parseFloat(
			elements[i].impact || elements[i].o?.impact || 1
		);

		/*console.log(
			'element',
			elements[i].name,
			'elements[i].likelihood',
			elements[i].likelihood || elements[i].o?.likelihood,
			'elements[i].impact',
			elements[i].impact || elements[i].o?.impact,
			'final likelihood',
			likelihood,
			'final impact',
			impact,
			elements[i]
		);*/

		total +=
			((likelihood - 1) * impact * impact) /
			((maxLikelihood - 1) * maxImpact * maxImpact);
		weights += impact * impact;
		count++;
	}

	let average = total / count;

	/*console.log(
		'total',
		total,
		'maxLikelihood',
		maxLikelihood,
		'weights',
		weights,
		'average',
		average
	);*/

	let currentPercentage = 100 - average * 100;

	//console.log('currentPercentage', currentPercentage);
	//console.groupEnd();
	return currentPercentage;
};

export const getOpportunityScore = (
	elements: any,
	maxLikelihood,
	maxImpact
) => {
	//console.group('getOpportunityScore');
	if (elements.filter((r) => r.isOpportunity).length == 0) {
		return null;
	}

	let result = 100 - getRiskScore(elements, maxLikelihood, maxImpact);

	//console.groupEnd();
	return result;
};

export const getIndicatorOrConstraintScore = (elements: any) => {
	if (!elements || elements.length == 0) {
		return null;
	}

	var total_weight = 0;
	var total = 0;

	for (var i = 0; i < elements.length; i++) {
		total_weight += elements[i].weight || 1;

		total += colorToPercent(elements[i].trend) * (elements[i].weight || 1);
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50;

	return currentPercentage;
};

export const getStakeholderScoreForScenarioDetailsColor = (element: any) => {
	let activeness = { undefined: 0, likely: 7, low: 18, medium: 33, high: 50 }[
		itemActiveness(element.x, element.y)
	];

	return element.y < 0 ? -activeness + 50 : activeness + 50;
};

export const getStakeholderScoreForScenarioDetailsRanking = (element: any) => {
	let activeness = {
		undefined: 0,
		likely: 70,
		low: 180,
		medium: 330,
		high: 500
	}[itemActiveness(element.x, element.y)];

	let weight = { low: 0, medium: 5, high: 10 }[
		element.impact ? element.impact : 'low'
	];
	let trend = { green: 3, red: 0, orange: 2, neutral: 1 }[
		element.trend ? element.trend : 'neutral'
	];

	let score = activeness + weight + trend;
	if (element.links && element.links.length > 0) {
		let linksStrength = element.links
			? sumBy(element.links, (l) => {
					return l.strength * 0.1;
				})
			: 0;
		score += Math.min(linksStrength, 10) / 100;
	}

	return element.y < 0 ? -score + 500 : score + 500;
};

const itemActiveness = function (x: Number, y: Number) {
	var d = Math.sqrt(x * x + y * y);

	if (isItemInactive({ x, y })) return 'undefined';

	if (d < radarHighToAverageBoundariesRatio * 100) return 'high';
	if (d < radarAverageToLowBoundariesRatio * 100) return 'medium';
	if (d <= 100) return 'low';
	if (d < radarLikelyToUndefinedBoundariesRatio * 100) return 'likely';
	return 'undefined';
};

const itemWeight = function (item) {
	//console.log('itemWeight', item.id, item.impact, '--------------')
	var weight = 1,
		activeness = 0;

	weight = { low: 0.6, medium: 2, high: 4 }[item.impact || 'low'];

	activeness = {
		undefined: 0,
		likely: 0.45,
		low: 0.6,
		medium: 1.6,
		high: 3.5
	}[itemActiveness(item.x, item.y)];

	//console.log('itemWeight', item.id, weight, item.trend)
	if (item.y > 0)
		weight *= { green: 1.2, red: 0.8, orange: 0.9, neutral: 1 }[
			item.trend ? item.trend : 'neutral'
		];
	else
		weight *= { green: 0.8, red: 1.2, orange: 1.1, neutral: 1 }[
			item.trend ? item.trend : 'neutral'
		];

	// Alters weight based on links
	if (item.links && item.links.length > 0) {
		let linksStrength = item.links
			? sumBy(item.links, (l) => {
					return l.strength * 0.4;
				})
			: 0;
		weight += (Math.min(linksStrength, 4) / 16) * weight;
	}

	//console.log('itemWeight - end ', item.id, weight)

	return item.y > 0 ? weight * activeness : -weight * activeness;
};

const normalizeTotalWeight = function (total, sum_positive, sum_negative) {
	//console.log('--- normalizeTotalWeight', total, sum_positive, sum_negative);

	var max = Math.max(sum_positive, -sum_negative);
	//console.log('normalizeTotalWeight: max', max);

	var v = total;

	//Math.min( total, max * 0.8 );
	//console.log('normalizeTotalWeight: Math.min( total, max * 0.8 )', v);

	//v = Math.max( v, -max * 0.8 );
	//console.log('normalizeTotalWeight: Math.max( v, -max * 0.8 )', v);

	if (max != 0) v = v / max;
	//console.log('normalizeTotalWeight: v / max', v);

	v = -v * 80 + 50;
	//console.log('normalizeTotalWeight: -v * 70 + 50', v);

	v = Math.min(100, v);
	v = Math.max(0, v);
	//console.log('normalizeTotalWeight: min 0 max 100', v);

	return v;
};

export const vulnerability = function (items) {
	//console.log('-------- vulnerability', items);

	if (!items || items.length == 0) {
		return null;
	}

	var total_weight = 0;
	var sum_negative = 0;
	var sum_positive = 0;

	for (var i = 0; i < items.length; i++) {
		var weight = itemWeight(items[i]);

		//console.log('vulnerability - weight after links', weight);

		total_weight += weight;

		//console.log('vulnerability - final weight', items[i].y()>0? weight*activeness : -weight*activeness);

		if (weight >= 0) {
			sum_positive += weight;
		} else {
			sum_negative += weight;
		}
	}

	//var global_max = Math.max( max, -min );

	/*console.log(
		'vulnerability - normalizeTotalWeight',
		total_weight,
		sum_positive,
		sum_negative,
		normalizeTotalWeight(total_weight, sum_positive, sum_negative)
	)*/
	return normalizeTotalWeight(total_weight, sum_positive, sum_negative);
};

export const scenarioScore = (project: any, scenario: any) => {
	//console.group('scenarioScore', project);
	let divisor = 0;
	let indicatorsScore = 0;
	let constraintsScore = 0;
	let stakeholdersScore = 0;
	let risksScore = 0;
	let opportunitiesScore = 0;
	let fishboneScore = 0;

	//console.group('scenarioScore', scenario?.name);

	if ((project?.indicators || []).length > 0) {
		indicatorsScore = vulnerabilityIndicators(
			(project?.indicators || []).map((i: any) =>
				getItemAlteredByScenario(i, scenario)
			)
		);

		divisor++;
	}

	if ((project?.constraints || []).length > 0) {
		constraintsScore = vulnerabilityConstraints(
			(project?.constraints || []).map((c: any) =>
				getItemAlteredByScenario(c, scenario)
			)
		);

		divisor++;
	}

	if ((project?.stakeholders || []).length > 0) {
		stakeholdersScore = vulnerability(
			(project?.stakeholders || []).map((s: any) =>
				getItemAlteredByScenario(s, scenario)
			)
		);

		divisor++;
	}

	if ((project.fishboneBranchs || []).length > 0) {
		let preparedData = project.fishboneBranchs.map((b: any) => {
			return {
				...b,
				items: b.items.map((i: any) =>
					getItemAlteredByScenario(i, scenario)
				)
			};
		});

		fishboneScore = getFishboneScore(preparedData);
		//console.log('fishboneScore', fishboneScore);
		divisor++;
	}

	const maxLikelihood =
		(project?.likelihoods &&
			maxBy(values(project?.likelihoods), 'id')?.id) ||
		3;
	const maxImpact =
		(project?.impacts && maxBy(values(project?.impacts), 'id')?.id) || 3;

	const risks = (project?.risks || []).filter((r) => !r.isOpportunity);
	if (risks.length > 0) {
		risksScore = getRiskScore(
			risks.map((s: any) => getItemAlteredByScenario(s, scenario)),
			maxLikelihood,
			maxImpact
		);

		//console.log('risksScore', risksScore);
		divisor++;
	}

	const opportunities = (project?.risks || []).filter(
		(r) => !!r.isOpportunity
	);
	if (opportunities.length > 0) {
		opportunitiesScore = getOpportunityScore(
			opportunities.map((s: any) =>
				getItemAlteredByScenario(s, scenario)
			),
			maxLikelihood,
			maxImpact
		);

		//console.log('opportunitiesScore', opportunitiesScore);

		divisor++;
	}

	//console.log('project?.stakeholders', project?.stakeholders);

	if (stakeholdersScore !== null && (project?.stakeholders || []).length > 0)
		stakeholdersScore = 100 - stakeholdersScore;

	/*console.log('stakeholdersScore', stakeholdersScore);
	console.log('indicatorsScore', indicatorsScore);
	console.log('constraintsScore', constraintsScore);
	console.log('risksScore', risksScore);
	console.log('opportunitiesScore', opportunitiesScore);
	console.log('divisor', divisor);*/

	let score =
		divisor > 0
			? (stakeholdersScore +
					indicatorsScore +
					constraintsScore +
					risksScore +
					opportunitiesScore +
					fishboneScore) /
				divisor
			: null;

	//console.log('final score', score);
	//console.groupEnd();
	return score;
};
