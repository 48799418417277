import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Popover, Tag } from 'antd';

import { maxBy, sortBy, values } from 'lodash';
import { faChevronDown, faChevronUp, faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useRiskController } from 'hooks/useRiskController';

import MiniIndicatorVulnerabilityScale from 'pages/Project/Indicators/MiniIndicatorVulnerabilityScale';
import MiniConstraintVulnerabilityScale from 'pages/Project/Constraints/MiniConstraintVulnerabilityScale';
import { ElementPopover } from './ElementPopover';
import IndicatorEditionModal from 'components/Modal/IndicatorEditionModal';
import ConstraintEditionModal from 'components/Modal/ConstraintEditionModal';
import ConfirmModal from 'components/Modal/ConfirmModal';


import { getOpportunityScore, getRiskScore, getScoreColor, singleHue } from 'utils/ranking';
import { getItemAlteredByScenario } from 'utils/rules';
import RadialVulnerabilityGauge from 'components/charts/RadialVulnerabilityGauge';
import MinSingleVulnerabilityScale from 'pages/Project/Risks/MiniSingleVulnerabilityScale.js';

import RiskEditionModal from 'components/Modal/RiskEditionModal';
import { useIndicatorController } from 'hooks/useIndicatorController';
import { useConstraintController } from 'hooks/useConstraintController';

import './SummaryColumn.sass';
import dayjs from 'dayjs';

export const SummaryColumn = (props: any) => {
	const { t } = useTranslation();
	const params: any = useParams();

	const { likelihoods = [], impacts = [], indicators = [], risks = [] } = props;

	const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
	const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

	const [editedItem, setEditedItem] = React.useState<any | null>(null);

	const { createRisk, updateRisk, deleteRisk } = useRiskController();
	const { createIndicator, updateIndicator, deleteIndicator } = useIndicatorController();
	const { createConstraint, updateConstraint, deleteConstraint } = useConstraintController();

	const allRisks = risks.filter(r => !r.isOpportunity);
	const allOpportunities = risks.filter(r => !!r.isOpportunity);

	const askDeleteConfirmation = async (type: string, id?: string) => {

		props.pushModal(
			<ConfirmModal
				title={t('models.' + type + '.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(type, params.id, id)}
				message={t(
					'models.' + type + '.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	// Create
	const handleCreateRisk = async (isOpportunity: Boolean = false) => {
		props.pushModal(
			<RiskEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					await createRisk(params.id, risks, values);
					props.popModal();
				}}
				tags={props.tags}
				id={false}
				values={{
					projectId: params.id,
					isOpportunity: isOpportunity,
					impactedIndicators: [],
					evaluatedAt: null
				}}
				riskCategories={props.riskCategories}
				likelihoods={props.likelihoods || []}
				impacts={props.impacts || []}
				risks={risks}
				indicators={indicators}
			></RiskEditionModal>
		);
	};

	// Create indicator
	const handleCreateIndicator = async () => {
		props.pushModal(
			<IndicatorEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
						evaluatedAt: values.evaluatedAt
					};

					let result = await createIndicator(params.id, variables)
					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
					evaluatedAt: null
				}}
			></IndicatorEditionModal>
		);
	};

	// Create indicator
	const handleCreateConstraint = async () => {
		props.pushModal(
			<ConstraintEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
						evaluatedAt: values.evaluatedAt
					};

					let result = await createConstraint(params.id, variables)
					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
					evaluatedAt: null
				}}
			></ConstraintEditionModal>
		);
	};

	const handleDelete = async (type: string, projectId: string, id: string | undefined) => {
		const handlers = {
			'indicator': () => deleteIndicator(projectId, id),
			'constraint': () => deleteConstraint(projectId, id),
			'risk': () => deleteRisk(projectId, id)
		};

		await handlers[type]();
	}

	// Update
	const handleUpdateIndicator = async (id: string, values: Object) => {
		await updateIndicator(id, props.indicators, props.scenario, values);
	};

	// Update
	const handleUpdateConstraint = async (id: string, values: Object) => {
		await updateConstraint(id, props.constraints, props.scenario, values);
	};

	return (
		<aside id="SummaryColumn" className={['noselection '].join(' ')}>

			<h6 className='text-center mt-4'>Key drivers</h6>
			<div
				style={{
					width: '240px',
					height: '180px',
					display: 'block',
					paddingTop: '70px',
					paddingLeft: '50px'
				}}
			>
				<RadialVulnerabilityGauge
					stakeholders={props.stakeholders}
					scenario={props.scenario}
					scenarios={props.scenarios}
					isFiltered={props.isFiltered}
				></RadialVulnerabilityGauge>
			</div>



			<div className="content">
				{props.features?.constraints && (
					<>
						<div className="text-left mt-4 mb-2 d-flex flex-column align-items-left">
							<span
								className="text-nowrap mb-1 d-flex"
								style={{ marginRight: '10px' }}
							>
								{t('pages.constraints.title')}

								<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}} onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									props.setPreferences(
										'pages',
										'navigator.constraints',
										!props.areConstraintsVisible
									);
								}}>
									<FontAwesomeIcon
										icon={!props.areConstraintsVisible ? faChevronDown : faChevronUp}
									></FontAwesomeIcon>
								</Button>
							</span>

							<MiniConstraintVulnerabilityScale
								scenario={props.scenario}
								scenarios={props.scenarios}
								constraints={(props.constraints || []).map((c) =>
									getItemAlteredByScenario(c, props.scenario)
								)}
							></MiniConstraintVulnerabilityScale>
						</div>
						{props.areConstraintsVisible && <div className="text-left">
							{props.constraints && props.constraints.length ? (
								sortBy(props.constraints || [], 'name').map(
									(i: any, index: number) => {
										let alteredItem = getItemAlteredByScenario(
											i,
											props.scenario
										);

										return (
											<Popover
												key={index}
												trigger={'click'}
												placement="topLeft"
												overlayStyle={{ maxWidth: '400px' }}
												title={
													<div>
														{i.name || "No name"}{' '}
														{!props.scenario?.id && <FontAwesomeIcon
															style={{
																marginLeft:
																	'0.5rem',
																fontSize: '1.2rem',
																cursor: 'pointer'
															}}
															icon={faEdit}
															onClick={() =>
																setEditedItem(i)
															}
														/>}
													</div>
												}
												content={
													<ElementPopover
														{...alteredItem}
														originalElement={i}
														onUpdate={
															handleUpdateConstraint
														}
														scenario={props.scenario}
														scenarios={props.scenarios}
													></ElementPopover>
												}
											>
												<Tag
													className={[
														'tag ImpactedConstraint unselected'
													].join(' ')}
												>
													<div
														className={
															'Trend v' +
															alteredItem.trend
														}
														style={
															alteredItem.trend ==
																'50'
																? {}
																: {
																	background:
																		getScoreColor(
																			alteredItem.trend
																		)
																}
														}
													></div>
													{i.name || "No name"}
												</Tag>
											</Popover>
										);
									}
								)
							) : (
								<div
									className="text-center"
									style={{ color: 'rgba(46, 160, 223, 0.3)' }}
								>
									{t('models.constraint.no_x')}
								</div>
							)}

							{!props.scenario && (
								<Tag
									onClick={handleCreateConstraint}
									className={'tag ImpactedIndicator unselected'}
									style={{ margin: 'auto' }}
								>
									+
								</Tag>
							)}
						</div>}
					</>
				)}

				{props.features?.indicators && (
					<>
						<div className="text-left mt-4 mb-2 d-flex flex-column align-items-left">
							<span
								className="text-nowrap mb-1 d-flex"
								style={{ marginRight: '10px' }}
							>
								{t('pages.indicators.title')}

								<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}} onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									props.setPreferences(
										'pages',
										'navigator.indicators',
										!props.areIndicatorsVisible
									);
								}}>
									<FontAwesomeIcon
										icon={!props.areIndicatorsVisible ? faChevronDown : faChevronUp}
									></FontAwesomeIcon>
								</Button>
							</span>

							<MiniIndicatorVulnerabilityScale
								scenario={props.scenario}
								scenarios={props.scenarios}
								indicators={(props.indicators || []).map((i: any) =>
									getItemAlteredByScenario(i, props.scenario)
								)}
							></MiniIndicatorVulnerabilityScale>
						</div>
						{props.areIndicatorsVisible && <div className="text-left">
							{props.indicators && props.indicators.length ? (
								sortBy(props.indicators || [], 'name').map(
									(i: any, index: number) => {
										let alteredItem = getItemAlteredByScenario(
											i,
											props.scenario
										);

										return (
											<Popover
												key={index}
												trigger={'click'}
												placement="topLeft"
												overlayStyle={{ maxWidth: '400px' }}
												title={
													<div>
														{i.name || "No name"}{' '}
														{!props.scenario?.id && <FontAwesomeIcon
															style={{
																marginLeft:
																	'0.5rem',
																fontSize: '1.2rem',
																cursor: 'pointer'
															}}
															icon={faEdit}
															onClick={() =>
																setEditedItem(i)
															}
														/>}
													</div>
												}
												content={
													<ElementPopover
														{...alteredItem}
														originalElement={i}
														scenario={props.scenario}
														scenarios={props.scenarios}
														onUpdate={
															handleUpdateIndicator
														}
													></ElementPopover>
												}
											>
												<Tag
													className={[
														'tag ImpactedIndicator unselected'
													].join(' ')}
												>
													<div
														className={
															'Trend v' +
															alteredItem.trend
														}
														style={
															alteredItem.trend ==
																'50'
																? {}
																: {
																	background:
																		getScoreColor(
																			alteredItem.trend
																		)
																}
														}
													></div>
													{i.name || "No name"}
												</Tag>
											</Popover>
										);
									}
								)
							) : (
								<div
									className="text-center"
									style={{ color: 'rgba(46, 160, 223, 0.3)' }}
								>
									{t('models.indicator.no_x')}
								</div>
							)}
							{!props.scenario && (
								<Tag
									onClick={handleCreateIndicator}
									className={'tag ImpactedIndicator unselected'}
								>
									+
								</Tag>
							)}
						</div>}
					</>
				)}

				{props.features?.risks && (
					<>
						<div className="text-left mt-4 mb-2 d-flex flex-column align-items-left">
							<span
								className="text-nowrap mb-1 d-flex"
								style={{ marginRight: '10px' }}
							>
								{t('models.risk.name_plural')}

								<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}} onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									props.setPreferences(
										'pages',
										'navigator.risks',
										!props.areRisksVisible
									);
								}}>
									<FontAwesomeIcon
										icon={!props.areRisksVisible ? faChevronDown : faChevronUp}
									></FontAwesomeIcon>
								</Button>
							</span>

							<MinSingleVulnerabilityScale
								scenario={props.scenario}
								scenarios={props.scenarios}
								elements={allRisks.map((c) => getItemAlteredByScenario(c, props.scenario))}
								maxLikelihood={maxLikelihood}
								maxImpact={maxImpact}
							></MinSingleVulnerabilityScale>
						</div>
						{props.areRisksVisible && <div className="text-left">
							{allRisks && allRisks.length ? (
								sortBy(allRisks || [], 'name').map(
									(i: any, index: number) => {
										let alteredItem = getItemAlteredByScenario(
											i,
											props.scenario
										);

										return (
											<Popover
												key={index}
												trigger={'click'}
												placement="topLeft"
												overlayStyle={{ maxWidth: '400px' }}
												title={
													<div>
														{i.name || "No name"}{' '}
														{!props.scenario &&
															<FontAwesomeIcon
																style={{
																	marginLeft:
																		'0.5rem',
																	fontSize: '1.2rem',
																	cursor: 'pointer'
																}}
																icon={faEdit}
																onClick={() =>
																	setEditedItem(i)
																}
															/>}
													</div>
												}
												content={
													<ElementPopover
														{...alteredItem}
														originalElement={i}
														onUpdate={(id, values) => updateRisk(id, risks, props.scenario, values)}
														scenario={props.scenario}
														scenarios={props.scenarios}
														likelihoods={props.likelihoods}
														impacts={props.impacts}

													></ElementPopover>
												}
											>
												<Tag
													className={[
														'tag ImpactedConstraint unselected'
													].join(' ')}
												>
													<div
														className={
															'Trend v'
														}
														style={
															alteredItem.trend ==
																'50'
																? {}
																: {
																	background:
																		singleHue(
																			getRiskScore([alteredItem], maxLikelihood, maxImpact),
																			false
																		)
																}
														}
													></div>
													{i.name || "No name"}
												</Tag>
											</Popover>
										);
									}
								)
							) : (
								<div
									className="text-center"
									style={{ color: 'rgba(46, 160, 223, 0.3)' }}
								>
									{t('models.risk.messages.no_x')}
								</div>
							)}

							{!props.scenario && (
								<Tag
									onClick={() => handleCreateRisk(false)}
									className={'tag ImpactedIndicator unselected'}
									style={{ margin: 'auto' }}
								>
									+
								</Tag>
							)}
						</div>}

						<div className="text-left mt-4 mb-2 d-flex flex-column align-items-left">
							<span
								className="text-nowrap mb-1 d-flex"
								style={{ marginRight: '10px' }}
							>
								{t('models.risk.name_plural_positive')}

								<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}} onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									props.setPreferences(
										'pages',
										'navigator.opportunities',
										!props.areOpportunitiesVisible
									);
								}}>
									<FontAwesomeIcon
										icon={!props.areOpportunitiesVisible ? faChevronDown : faChevronUp}
									></FontAwesomeIcon>
								</Button>
							</span>

							<MinSingleVulnerabilityScale
								scenario={props.scenario}
								scenarios={props.scenarios}
								positive={true}
								elements={allOpportunities.map((c) => getItemAlteredByScenario(c, props.scenario))}
								maxLikelihood={maxLikelihood}
								maxImpact={maxImpact}
							></MinSingleVulnerabilityScale>
						</div>
						{props.areOpportunitiesVisible && <div className="text-left">
							{allOpportunities && allOpportunities.length ? (
								sortBy(allOpportunities || [], 'name').map(
									(i: any, index: number) => {
										let alteredItem = getItemAlteredByScenario(
											i,
											props.scenario
										);

										return (
											<Popover
												key={index}
												trigger={'click'}
												title={
													<div>
														{i.name || "No name"}{' '}
														<FontAwesomeIcon
															style={{
																marginLeft:
																	'0.5rem',
																fontSize: '1.2rem',
																cursor: 'pointer'
															}}
															icon={faEdit}
															onClick={() =>
																setEditedItem(i)
															}
														/>
													</div>
												}
												content={
													<ElementPopover
														{...alteredItem}
														originalElement={i}
														onUpdate={(id, values) => updateRisk(id, risks, props.scenario, values)}
														scenario={props.scenario}
														scenarios={props.scenarios}
														likelihoods={props.likelihoods}
														impacts={props.impacts}
													></ElementPopover>
												}
											>
												<Tag
													className={[
														'tag ImpactedConstraint unselected'
													].join(' ')}
												>
													<div
														className={
															'Trend v'
														}
														style={
															alteredItem.trend ==
																'50'
																? {}
																: {
																	background:
																		singleHue(
																			getOpportunityScore([alteredItem], maxLikelihood, maxImpact),
																			true
																		)
																}
														}
													></div>
													{i.name || "No name"}
												</Tag>
											</Popover>
										);
									}
								)
							) : (
								<div
									className="text-center"
									style={{ color: 'rgba(46, 160, 223, 0.3)' }}
								>
									{t('models.risk.messages.no_x_positive')}
								</div>
							)}

							{!props.scenario && (
								<Tag
									onClick={() => handleCreateRisk(true)}
									className={'tag ImpactedIndicator unselected'}
									style={{ margin: 'auto' }}
								>
									+
								</Tag>
							)}
						</div>}
					</>
				)}
			</div>



			{editedItem && editedItem.__typename == 'Indicator' && (
				<IndicatorEditionModal
					width={"70%"}
					onHide={() => {
						setEditedItem(null);
					}}
					onDelete={() => {
						askDeleteConfirmation('indicator', editedItem.id);
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						handleUpdateIndicator(editedItem.id, values);
						setEditedItem(null);
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
						reference: editedItem?.reference,
						evaluatedAt: editedItem?.evaluatedAt ? dayjs(editedItem?.evaluatedAt) : null
					}}
				></IndicatorEditionModal>
			)}

			{editedItem && editedItem.__typename == 'Constraint' && (
				<ConstraintEditionModal
					width={"70%"}
					onHide={() => {
						setEditedItem(null);
					}}
					onDelete={() => {
						askDeleteConfirmation('constraint', editedItem.id);
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						handleUpdateConstraint(editedItem.id, values);
						setEditedItem(null);
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
						reference: editedItem?.reference,
						evaluatedAt: editedItem?.evaluatedAt ? dayjs(editedItem?.evaluatedAt) : null
					}}
				></ConstraintEditionModal>
			)}

			{editedItem && editedItem.__typename == 'Risk' && (
				<RiskEditionModal
					onHide={() => {
						setEditedItem(null);
					}}
					onDelete={() => {
						console.log('delete editedItem', editedItem);
						askDeleteConfirmation('risk', editedItem.id);
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						updateRisk(
							editedItem.id,
							risks,
							props.scenario,
							values
						);
						setEditedItem(null);
					}}
					tags={props.tags}
					id={editedItem?.id}
					likelihoods={props.likelihoods || []}
					impacts={props.impacts || []}
					values={{
						name: editedItem?.name,
						impact: editedItem?.impact || 1,
						likelihood: editedItem?.likelihood || 1,
						description: editedItem?.description || '',
						riskCategoryId: editedItem?.riskCategoryId,
						comment: editedItem?.comment || '',
						isOpportunity: editedItem?.isOpportunity || false,
						matchingOpportunityId: editedItem?.matchingOpportunityId || null,
						taggings: editedItem?.taggings?.map(t => t.tagId) || [],
						reference: editedItem?.reference,
						evaluatedAt: editedItem?.evaluatedAt,
						impactedIndicators: (editedItem?.impactedIndicators || []).map(ii => ii.indicatorId),
					}}
					riskCategories={props.riskCategories}
					risks={risks.filter(r => r.id != editedItem?.id)}
					indicators={indicators}
				></RiskEditionModal>
			)}
		</aside>
	);
};
