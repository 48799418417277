import { Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export const FishboneTail = (props: any) => {
	const insideMargin = 0.5 * props.width;
	const { t } = useTranslation();

	const points = [
		[0, 0],
		[props.width, props.height / 2],
		[0, props.height],
		[insideMargin, props.height / 2],
		[0, 0]
	];

	return (
		<g>
			<polygon
				strokeWidth={1}
				stroke="#A6B9C8"
				fill="#FFFFFF11"
				points={points.map((p) => p.join(',')).join(' ')}
			></polygon>

			<text
				fill="white"
				fontSize={6}
				dy={props.height / 2 + 3}
				dx={0}
			>
				{t("models.scenario.baseline")}
			</text>

		</g>
	);
};
