import { Tooltip } from 'antd';
import { sumBy } from 'lodash';
import dayjs from 'dayjs';
import { colorToPercent, getScoreColor, hue, percentToHue, percentToSingleHue } from 'utils/ranking';

import './FishboneHead.sass';

export const FishboneHead = (props: any) => {
	console.log('FishboneHead', props);

	const points = [
		[0, 0],
		[props.width, props.height / 2],
		[0, props.height],
		[0, 0]
	];

	const vulnerabilities = ((props && props.branchs) || []).map((b: any) => {
		const pointsWeight = sumBy(b.items, (p: any) => p.weight || 1);

		return b.items && b.items.length > 0
			? (sumBy(
				b.items,
				(p: any) => colorToPercent(p.trend) * (p.weight || 1)
			) /
				pointsWeight) *
			(b.weight || 1)
			: 50;
	});

	const totalWeight = sumBy(
		(props && props.branchs) || [],
		(b) => b.weight || 1
	);
	const completions = ((props && props.branchs) || []).map((b: any) => {
		const pointsWeight = sumBy(b.items, (p: any) => p.weight || 1);

		return b.items && b.items.length > 0
			? (sumBy(
				b.items,
				(p: any) => (p.completion || 0) * (p.weight || 1)
			) /
				pointsWeight) *
			(b.weight || 1)
			: 0;
	});

	console.log('FishboneHead completions', completions, totalWeight);

	const vulnerability =
		vulnerabilities.length > 0
			? sumBy(vulnerabilities, (v) => v) / totalWeight
			: 50;

	const completion =
		completions.length > 0 ? sumBy(completions, (v) => v) / totalWeight : 0;

	const lines = [];
	let lineCounter = 0;
	let remainingGoal = props.description ? props.description.split(' ') : [];
	const maxChars = props.width / 8;
	while (remainingGoal.length > 0) {
		if (!lines[lineCounter]) lines[lineCounter] = '';
		while (
			lines[lineCounter].length < maxChars &&
			remainingGoal.length > 0
		) {
			lines[lineCounter] += remainingGoal.shift() + ' ';
		}
		if (lines[lineCounter].length == 0)
			lines[lineCounter] += remainingGoal.shift() + ' ';
		lineCounter++;
	}

	const color = getScoreColor(vulnerability);

	return (
		<Tooltip
			overlayStyle={{ minWidth: '400px' }}
			title={
				<div>
					<div className="d-flex pb-3 ">
						<b>
							{props.t(
								'models.project.fields.fishbone_description'
							)}{' '}
							<small
								style={{ fontSize: '10px', marginLeft: '5px' }}
							>
								(click to edit)
							</small>
						</b>
					</div>

					<div
						className="d-flex pb-3 mb-2"
						style={{ borderBottom: '1px solid #FFFFFF22' }}
					>
						<div>
							<small>
								{props.fishboneDescription ||
									'No ' +
									props.t(
										'models.project.fields.fishbone_description'
									)}
							</small>
						</div>
					</div>

					<div
						className="d-flex pb-3 mb-2"
						style={{ borderBottom: '1px solid #FFFFFF22' }}
					>
						<div>
							<small>{props.description || 'No goal'}</small>
						</div>
					</div>

					<div className="d-flex mb-2">
						<div style={{ width: '110px', flexShrink: 0 }}>
							Completion
						</div>
						<div>
							<b>{completion.toFixed(0)}%</b>
						</div>
					</div>

					<div className="d-flex mb-2">
						<div style={{ width: '110px', flexShrink: 0 }}>
							Status
						</div>
						<div
							className="MiniIndicatorVulnerabilityScale"
							style={{ width: '200px', marginTop: '10px' }}
						>
							<div
								id={'bar_marker'}
								style={{ left: vulnerability + '%' }}
							></div>
						</div>
					</div>

					{(props.fishboneStartOn || props.fishboneEndOn) && (
						<div className="d-flex mb-2">
							<div style={{ width: '110px', flexShrink: 0 }}>
								Dates
							</div>
							<div>
								{props.fishboneStartOn && (
									<small>
										{dayjs(props.fishboneStartOn).format(
											'DD/MM/YYYY'
										)}
									</small>
								)}
							</div>
							{props.fishboneStartOn && props.fishboneEndOn && (
								<div className="mr-1 ml-1">-</div>
							)}
							<div>
								{props.fishboneEndOn &&
									!props.fishboneStartOn &&
									'Until '}
								{props.fishboneEndOn && (
									<small>
										{dayjs(props.fishboneEndOn).format(
											'DD/MM/YYYY'
										)}
									</small>
								)}
							</div>
						</div>
					)}

					{props.responsible && (
						<div className="d-flex mb-2">
							<div style={{ width: '110px', flexShrink: 0 }}>
								Responsible
							</div>
							<div>{props.responsible && props.responsible}</div>
						</div>
					)}
				</div>
			}
		>
			<g className="FishboneHead" onClick={props.onClick}>
				<polygon
					strokeWidth={1}
					stroke="#A6B9C8"
					fill="#FFFFFF11"
					points={points.map((p) => p.join(',')).join(' ')}
				></polygon>

				<g
					transform={
						'translate(' +
						(12) +
						', ' +
						(props.height / 2) +
						')'
					}
				>

					<circle
						r={7}
						fill={
							vulnerability == '50'
								? '#E70549'
								: color
						}
					/>
					<circle
						r={7}
						fill={
							vulnerability == '50'
								? '#24CCB8'
								: color
						}
						clipPath="url(#cut-off)"
					/>

					<text
						dx={18}
						dy={3.5}
						fill="white"
						fontSize={9}
						textAnchor="middle"
						width={100}
						height={10}
					>
						{completion.toFixed(0)}%

					</text>
				</g>
			</g>
		</Tooltip>
	);
};
