import React, { useRef, useEffect } from 'react';

import { keyBy } from 'lodash';
import { isRiskOrOpportunityBetter, isRiskOrOpportunityWorse } from 'utils/rules';
import { indicatorsMargin as baseMargin, circleDx, circleDy, circlePlacementDx, circlePlacementDy, circleSpacer, textDx, textDy } from '../RiskScatterFullChart.functions';

const isSelected = (pointsList: string[], pointId) => {
    if (!pointsList) return false;
    return pointsList.find(p => p.id == pointId);
}

const sizes = {
    "0": 1,
    "1": 3,
    "2": 5,
    "3": 7
}

export const IndicatorsPointsCloud = (props: any) => {

    const [xy, setXY] = React.useState([0, 0]);

    const { data } = props;

    // Count the number of risks that have the same likelihood and impact
    const pointsCount = data.reduce((acc, indicator) => {
        const key = "" + indicator.trend
        acc[key] = (acc[key] || 0) + 1;
        return acc;
    }, {});

    const similarIndicators = keyBy((data || []).map(r => ({
        ...r,
        similarCount: pointsCount[r.trend] - 1,
        index: data.filter(fr => fr.trend == r.trend)
            .findIndex(d => d.id === r.id)
    })), "id");

    const margin = { top: 30, right: 30, bottom: 40, left: 40 },
        width = props.width - margin.left - margin.right

    return (

        <g className="wrapper" >

            {
                props.data.map((d, index) => {

                    const isWorse = isRiskOrOpportunityWorse(d);
                    const isBetter = isRiskOrOpportunityBetter(d);

                    return <g key={d.id}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (props.onClick) props.onClick(d)
                        }}
                        onMouseOver={() => {
                            if (props.onHover) props.onHover(d)
                        }}
                        onMouseOut={() => {
                            if (props.onHover) props.onHover(null)
                        }}
                        style={{
                            transform: `translate(${props.x(d.trend)}px, 0)`,
                            transition: "all 2.5s"
                        }}
                    >
                        <circle
                            className="risk-circle"
                            cx={circleSpacer(similarIndicators[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDx(d, similarIndicators)}
                            cy={circleSpacer(similarIndicators[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDy(d, similarIndicators)}
                            r={sizes[d.weight]}
                            fill={isSelected(props.selectedPoints, d.id) ? "orange" : isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" :
                                    "#000000"
                            }
                            strokeWidth={isSelected(props.selectedPoints, d.id) ? "4" : isWorse ? "6" :
                                isBetter ? "6" :
                                    "6"
                            }
                            stroke={isSelected(props.selectedPoints, d.id) ? "orange" : isWorse && d.isOpportunity ? "#00000044" : isWorse && !d.isOpportunity ? "#FFFFFF55" :
                                isBetter && d.isOpportunity ? "#FFFFFF55" : isBetter && !d.isOpportunity ? "#00000044" :
                                    "#00000001"
                            }

                            style={{
                                transition: isSelected(props.selectedPoints, d.id) ? "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s" : "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                        >

                        </circle>

                        <circle
                            className="risk-circle"
                            cx={circleSpacer(similarIndicators[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDx(d, similarIndicators)}
                            cy={circleSpacer(similarIndicators[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDy(d, similarIndicators)}
                            r={sizes[d.weight]}
                            fill={isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" :
                                    "#000000"
                            }
                            strokeWidth={isWorse ? "1" :
                                isBetter ? "1" :
                                    "6"
                            }
                            stroke={isWorse ? "#FFFFFF" :
                                isBetter ? "#FFFFFF" :
                                    "#00000001"
                            }
                            dx={circleDx(d, similarIndicators, props.width, baseMargin)}
                            dy={circleDy(d, similarIndicators, props.width, baseMargin)}
                            style={{
                                transition: isSelected(props.selectedPoints, d.id) ? "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, stroke-width 2.5s, stroke 2.5s" : "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"

                            }}
                        >

                        </circle>


                        <text
                            className="risk-label"
                            x={0}
                            y={0}
                            dx={textDx(d, similarIndicators, props.width, baseMargin)}
                            dy={textDy(d, similarIndicators, props.width, baseMargin) + 1}
                            fill={isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" : "#00000077"}
                            strokeWidth={isWorse ? "#FFFFFF" :
                                isBetter ? "#FFFFFF" :
                                    "6"
                            }
                            stroke={isWorse ? "#FFFFFF55" :
                                isBetter ? "#FFFFFF55" :
                                    "#00000001"
                            }
                            style={{
                                fontSize: "12px",
                                textAnchor: Math.abs(circlePlacementDx(d, similarIndicators) * 10) < 0.1 ? "middle" :
                                    circlePlacementDx(d, similarIndicators) < 0 ? "end" :
                                        circlePlacementDx(d, similarIndicators) > 0 ? "start" : "middle",
                                alignmentBaseline: "middle",
                                transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                            transform='translate(0, -10)'

                        >
                            {d.reference || "S" + ((d.index + 1 + "").padStart(2, "0"))}
                            <circle
                                r={2.5}
                                fill="#000000"
                            ></circle>
                        </text>

                        <text
                            className="risk-label"
                            x={0}
                            y={0}
                            dx={textDx(d, similarIndicators, props.width, baseMargin)}
                            dy={textDy(d, similarIndicators, props.width, baseMargin) + 1}
                            fill={"#000000"}
                            style={{
                                fontSize: "12px",
                                textAnchor: Math.abs(circlePlacementDx(d, similarIndicators) * 10) < 0.1 ? "middle" :
                                    circlePlacementDx(d, similarIndicators) < 0 ? "end" :
                                        circlePlacementDx(d, similarIndicators) > 0 ? "start" : "middle",
                                alignmentBaseline: "middle",
                                transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                            transform='translate(0, -10)'

                        >
                            {d.reference || "S" + ((d.index + 1 + "").padStart(2, "0"))}
                            <circle
                                r={2.5}
                                fill="#000000"
                            ></circle>
                        </text>



                    </g>

                })
            }
        </g >





    );
};
