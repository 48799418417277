export default {
	form: {
		required_error: 'This field is required',
		max_length_exceeded_error:
			'This field is too long ({{length}} characters max)'
	},
	legal: {
		i_consent: 'I consent',
		agreement_title: 'Agreement',
		agreement_content:
			'By using Gerositus, you agree to our <1>Terms & conditions</1> and <2>Privacy Policy</2>.',
		agreement_terms_conditions: 'Terms & conditions',
		agreement_privacy_policy: 'Privacy Policy',
		ai_disclaimer:
			'Hi, this is Maestro, the Gerositus AI. By asking questions to Maestro, you understand that it will search in a closed, proprietary knowledge base only available to you. ',
		ai_disclaimer_consent: 'I consent to use Maestro AI',
		ai_project_disclaimer:
			"By default, I can't access any information regarding your project. If you want me to access your project's data, you need to explicitly give me permission. This consent is not mandatory to use AI, it will just help personnalizing the answers.",

		ai_project_consent: 'I grant access to my project data'
	},
	yes: 'Yes',
	no: 'No',
	not_defined: 'Not defined',
	please_wait: 'Please wait'
};
