import { Popover, Tooltip } from 'antd';
import React from 'react';

import "./FishboneBody.sass";

export const FishboneBody = (props: any) => {
	return (

		<g className="FishboneBody LineGroup">
			<g transform={'translate(' + props.leftOffset + ')'}>

				<line
					strokeWidth={15}
					x1={0}
					x2={props.width}
					y1={props.height / 2}
					y2={props.height / 2}
					stroke="#FFFFFF00"
				></line>

				<line
					strokeWidth={2}
					x1={0}
					x2={props.width}
					y1={props.height / 2}
					y2={props.height / 2}
					stroke="#A6B9C8"
				></line>

				<g
					className="add"
					onClick={props.onCreateBranch}
					transform={'translate(' + (props.width / 2 - 7.5) + ' ' + props.height / 2 + ')'}
				>
					<circle r={15} fill={'#CCCCCC'}></circle>
					<text fill="white" fontSize={36} dx={-10} dy={13}>
						+
					</text>
				</g>

				{props.children.map((c, index) => {
					let chunkSize = (props.width * 0.9) / props.children.length;

					return React.cloneElement(c, {
						position: index % 2 ? 'top' : 'bottom',
						odd: index % 4 == 1 || index % 4 == 2,
						width: chunkSize * 0.8,
						height: props.height / 2,
						leftOffset: index * chunkSize,
						debug: index == 1
					});
				})}
			</g>
		</g>
	);
};
